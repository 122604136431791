<template>
<div class="container-projects">
  <div :class="[showBest ? 'best-thesis':'']">
  <div :class="['project-tile', project.attributes.field_best_project ? 'awarded': '']" v-for="(project, index) of filteredProjects" :key="index">
    <div class="project-tile--bg"></div>
      <div class="container">
        <div class="row">
          <div class="col-lg-8 title-container">
            <h6 class="label-w-icon" v-if="project.field_project_author">
              <router-link :to="studentLink(project.field_project_author)">
                <img src="@/assets/icon-student-white.svg" alt="" v-if="showBest">
                <img src="@/assets/icon-student.svg" alt="" v-else>
                {{ personName(project.field_project_author) }}
              </router-link>
            </h6>
            <h2>{{ project.attributes.title }}</h2>
          <div class="collapse" :id="'collapse'+project.id">
            <div>
              <p v-if="!showOngoing && project.attributes.field_shared_description" v-html="project.attributes.field_shared_description.processed"></p>
            </div>
          </div>
          <button v-if="project.attributes.field_shared_description"
            class="read-more accordion"  :data-toggle="'collapse'" :data-target="'#collapse' + project.id">
            Read abstract
          </button>
          </div>
          <div class="col-lg-4 metadata">
            <div class="row mb-lg-3 mb-3">
              <div class="col-lg-6 col">
                <div v-for="(field_sponsor,index) of project.field_sponsor_ref" :key="index">
                  <p class="label">{{field_sponsor.attributes.field_label}}</p>
                  <h5 class="green" v-for="partner of getProjectPartners(field_sponsor)" :key="partner.id">{{partner.attributes.title}}</h5>
                </div>
              </div>
              <div class="col-lg-6 col" v-if="!showOngoing && project.field_project_author ">
                <p class="label">Year:</p>
                <h5 class="green">{{(project.academicYear)}}</h5>
              </div>
            </div>
            <div class="row" v-if="!showOngoing && project.field_shared_attachment[0]">
              <a target="_blank" :href="endpoint + project.field_shared_attachment[0].attributes.uri.url" class="cta text-uppercase">
                go to the thesis
              </a>
            </div>
          </div>
        </div>
      </div>
  </div>
  </div>
</div>
</template>
<script>
import { slugify } from '@/libs/utils'
import { fetchNodes } from "@/libs/drupalClient";

export default {
  data() {
    return {
      endpoint: process.env.VUE_APP_ENDPOINT,
      projects: [],
      fetchedPartnerUrls: [],
      academicYears:[],
      partners: []
    };
  },
  props:{
    showOngoing: {
      type: Boolean,
      default: false
    },
    showBest: {
      type: Boolean,
      default: false
    },
  },
  computed:{
    filteredProjects(){
      let filtered = this.projects;



      if(this.showOngoing){
        filtered = filtered.filter((project)=>{
          return project.attributes.field_project_status !== 'complete'
        });
      } else {
        filtered = filtered.filter((project)=>{
          return project.attributes.field_project_status === 'complete'
        });
      }

      if(this.showBest){
        filtered = filtered.filter((project)=>{
          return project.attributes.field_best_project;
        });
      }

      if(this.$route.query.partner){
        filtered = filtered.filter(proj=>{
          return proj.partnerIds.includes(this.$route.query.partner);
        })
      }
      return filtered.sort((a,b)=>{
        var yearA = parseInt(a.academicYear);
        var yearB = parseInt(b.academicYear);

        if(yearA & yearB){
          if(yearA < yearB){
            return 1
          } else if( yearA > yearB){
            return -1
          } else {
            if(a.attributes.title > b.attributes.title){
              return 1
            } else if(a.attributes.title < b.attributes.title){
              return -1
            }
            return 0
          }
        } else {
          return 0
        }
      });
    }
  },
  methods: {
    studentLink(person){
      let personSlug = slugify(this.personName(person));
      return `/students/${personSlug}-${person.attributes.drupal_internal__nid}`
    },
    personName(person) {
      return person.attributes.field_first_name + " " + person.attributes.field_person_last_name;
    },
    fetchPartner(partner_url){
      if(!this.fetchedPartnerUrls.includes(partner_url)){
        this.fetchedPartnerUrls.push(partner_url);
        fetch(partner_url)
          .then(res => res.json())
          .then((json) => {
            this.partners.push(json.data)
          })
      }
    },
    fetchProjects() {
      return fetchNodes('project', 
        { include:['field_course_lecturer','field_project_author','field_sponsor_ref','field_shared_attachment'] }
      )
        .then((nodes) => {
          let projects = nodes;
        
          projects.forEach( project =>{
            /* fetch dati dei partner e aggiungo ai partnerIds del progetto */
            project.partnerIds = [];
            project.field_sponsor_ref.forEach((sponsor_ref=>{
              sponsor_ref.relationships.field_partner_ref.data.forEach(partnerData=>{
                project.partnerIds.push(partnerData.id);
                this.fetchPartner(`${process.env.VUE_APP_ENDPOINT}/jsonapi/node/partner/${partnerData.id}`);
              })
            }))

          });
          return projects;
        });
        
    },

    fetchAcademicYears(){
      return fetch(`${process.env.VUE_APP_ENDPOINT}/jsonapi/taxonomy_term/academic_year/`)
        .then((res) => res.json())
        .then((response) => {
          return this.academicYears = response.data;
      });
    },
    getProjectPartners(field_sponsor){
      return this.partners.filter(partner=>{
        return field_sponsor.relationships.field_partner_ref.data.find((partner_ref)=>{
            return partner.id === partner_ref.id
        });
      })
    },
    getAcademicYear(author){
      var acYear =  this.academicYears.find(year=>{
          return author.relationships.field_academic_year.data.find((authorYear)=>{
            return authorYear.id === year.id
        });
      });
      if(acYear){
        return  acYear.attributes.name
      }
    }
  },
  mounted() {
    // aspetto che entrambi i fetch siano completati
    Promise.all([
      this.fetchAcademicYears(),
      this.fetchProjects()
    ]).then( values =>{
      let [academicYears, projects] = values;
      projects.forEach(project=>{
        if(project.field_project_author){
          project.academicYear = this.getAcademicYear(project.field_project_author, academicYears)
        }
      });
      this.projects = projects;
    })
  },
};
</script>
<style lang="scss" scoped>
.container-projects {
  margin-top: 3rem;
}
h2 {
  font-size: 1.625rem;
  line-height: 2rem;
  margin-bottom: 2rem;
}
h5{
  font-size: 1.25rem;
}
.metadata {
  &>div{
    display: flex;
    flex-direction: row-reverse;
  }
  h5 {
    line-height: 24px;
  }
}
.label{
  color:#ACACAC;
  text-transform: uppercase;
  font-size: 0.875rem;
  margin-bottom:0px;
}


.project-tile {
  position: relative;
  margin-bottom: 20px;
  .container {
    padding: 37px 0;
  }
  .project-tile--bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 98%;
    height: 100%;
    border-top-right-radius: 200px;
    border-bottom-right-radius: 200px;
    background-color: #f7f7f7;
    z-index: -1;
  }
  &:nth-child(odd) {
    .project-tile--bg {
      border-radius: 0;
      border-top-left-radius: 200px;
      border-bottom-left-radius: 200px;
      left: auto;
      right: 0;
    }
  }
}
.title-container {
  padding-right: 100px;
}
.project-tile.awarded .title-container:before {
  content:"";
  display: block;
  background: url("../assets/icon-ribbon-awarded.svg");
  background-size: 100%;
  width: 40px;
  height: 66px;
  position: absolute;
  top: -37px;
  border-radius: 0px 0px 50px 50px;
  right: 40px;
}
.project-tile.awarded .title-container:after {
  content: "Awarded";
  display: block;
  position: absolute;
  top: -30px;
  font-size: 0.875rem;
  right: 93px;
}



.best-thesis{
  .project-tile--bg{
    background: #293446;
  }
  h2 {
    color:#1DC07E
  }
  .label-w-icon{
    color: #fff;
  }
  &::v-deep p{
    color:#fff;
  }

  .project-tile.awarded .title-container:before {
    content:"";
    display: block;
    background: url("../assets/icon-ribbon-awarded-green.svg");
  }
}
@media (max-width:992px){
  .project-tile:nth-child(odd) .project-tile--bg,
  .project-tile .project-tile--bg {
    width: 100%;
    border-radius: 0;
  }
  .title-container {
    padding-right: 15px;
  }
  .metadata{
    margin-top: 20px;
  }
  .cta{
    margin: 0px auto;
  }
}

@media (max-width:1300px)and(min-width:1200px){
.project-tile {
  .container {
        padding: 37px 67px;
  }
}
}
@media (max-width:1090px)and(min-width:992px){
  .project-tile {
    .container {
          padding: 37px 67px;
    }
  }
}
@media (max-width:576px){
  .metadata{
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 0px;
    margin-top: 0px;
    & > div{
      display: flex;
      flex-direction: column;
    }
  }
}
</style>